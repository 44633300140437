export { default as GoogleIcon } from "./google.svg";
export { default as GithubIcon } from "./github.svg";
export { default as SearchIcon } from "./search.svg";
export { default as CloseIcon } from "./close.svg";
export { default as HeartIcon } from "./heart.svg";
export { default as DownloadIcon } from "./download.svg";
export { default as NotificationIcon } from "./notification.svg";
export { default as ShellIcon } from "./shell.svg";
export { default as MessageIcon } from "./message.svg";
export { default as StandaloneIcon } from "./standalone.svg";
export { default as ShieldIcon } from "./shield.svg";
export { default as LibraryIcon } from "./library.svg";
export { default as ChevronRightIcon } from "./chevron-right.svg";
export { default as CheckMarkIcon } from "./checkmark.svg";
export { default as TwitterIcon } from "./twitter.svg";
export { default as WorldIcon } from "./world.svg";
export { default as PinIcon } from "./pin.svg";
export { default as CameraIcon } from "./camera.svg";
export { default as DockerIcon } from "./docker.svg";
export { default as EyeIcon } from "./eye.svg";
export { default as DownloadOutlinedIcon } from "./download_outlined.svg";
export { default as HeartOutlinedIcon } from "./heart_outlined.svg";
export { default as StarIcon } from "./star.svg";
export { default as IssueIcon } from "./issue.svg";
export { default as BranchIcon } from "./branch.svg";
export { default as FolderIcon } from "./folder.svg";
export { default as FileIcon } from "./file.svg";
export { default as PlusSettingIcon } from "./plus-setting.svg";
export { default as InvitePendingSettingIcon } from "./invite-pending-setting.svg";
export { default as CloseModalIcon } from "./close-modal.svg";
export { default as ShieldCheckIcon } from "./shieldCheck.svg";
export { default as ChevronLeftIcon } from "./chevron-left.svg";
export { default as HowToUseDashboardIcon } from "./how-to-use.svg";
export { default as PublishYourPackageDashboardIcon } from "./publish-your-package.svg";
export { default as AvatarInviteEmailIcon } from "./avatar-invite-email.svg";
export { default as InviteEmailIcon } from "./invite-email.svg";
export { default as KeyIcon } from "./keyIcon.svg";
export { default as DeleteIcon } from "./delete.svg";
export { default as TransferIcon } from "./transfer.svg";
export { default as Shell } from "./shell-icon.svg";
export { default as WasmerIcon } from "./wasmer.svg";
export { default as AddIcon } from "./addIcon.svg";
export { default as NamespaceDefaultIcon } from "./namespace-default.svg";
export { default as TickIcon } from "./checkIcon.svg";
export { default as MenuIcon } from "./menuIcon.svg";
export { default as PkgCompletenessIcon } from "./package-completeness.svg";
export { default as PkgCircleIcon } from "./circle.svg";
export { default as DownloadPackage } from "./downloadPackage.svg";
export { default as ExternalLink } from "./external-link.svg";
export { default as JavascriptIcon } from "./javascript.svg";

// New icons additions
export { default as BaselineSearchIcon } from "./baseline-search.svg";
export { default as SortIcon } from "./sort.svg";
export { default as ConsoleIcon } from "./console.svg";
export { default as ArrowDownIcon } from "./arrow-down.svg";
// export { default as Settings } from "./settings.svg";
